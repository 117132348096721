import React, { useEffect, useState } from "react";

import { AppFooter } from "@/components/AppFooter";
import { AppHeader } from "@/components/AppHeader";
import { BenefitsForSellers } from "@/components/BenefitsForSellers";
import { DataModal } from "@/components/DataModal";
import { SuccessModal } from "@/components/DataModal/SuccessModal";
import { HowToStartSelling } from "@/components/HowToStartSelling";
import { ImagesRows } from "@/components/ImagesRows";
import { JoinSellers } from "@/components/JoinSellers";
import { QuickRegistration } from "@/components/QuickRegistration";
import { SellersSaleInfo } from "@/components/SellersSaleInfo";
import { StartWithUs } from "@/components/StartWithUs";
import { WeAreReadyToHelp } from "@/components/WeAreReadyToHelp";
import { WorkWithUs } from "@/components/WorkWithUs";
import { useAppContext } from "./context";
import { PartnerProgram } from "@/components/PartnerProgram";

import { useDispatch, useSelector } from "react-redux";
import { FailureModal } from "./components/DataModal/FailureModal";
import { useLocation } from "react-router-dom";
import { submitUtmRequest } from "./modules/slices/utmSlice";

import "./App.scss";

function App() {
  const { isModalOpen, toggleModal } = useAppContext();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);
  const errorPayload = useSelector((state) => state.amo.error);
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const source = urlParams.get("utm_source");
  const prevUtmSource = localStorage.getItem("source");
  const isNewUtmSource = prevUtmSource !== source;

  if (source) {
    localStorage.setItem("source", source);
  }

  useEffect(() => {
    if (localStorage.getItem("source") && source && isNewUtmSource) {
      dispatch(submitUtmRequest({ name: `${source}_landing` }));
    }
  }, [dispatch, isNewUtmSource, source]);

  return (
    <div className="app">
      <AppHeader />
      <StartWithUs />
      <SellersSaleInfo />
      <WorkWithUs />
      <PartnerProgram />
      <HowToStartSelling />
      <BenefitsForSellers />
      <QuickRegistration />
      <JoinSellers />
      {/*<ImagesRows />*/}
      <WeAreReadyToHelp />
      <AppFooter />
      <DataModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        showSuccessModal={setIsSubmitSuccess}
        showFaliureModal={setIsSubmitFailed}
      />
      {isSubmitSuccess && (
        <SuccessModal
          title={"Ваша заявка отправлена!"}
          buttonText={"Перейти в личный кабинет"}
          description={
            "Ваша заявка находится в обработке. В течение часа с Вами свяжется наш специалист для дальнейшей консультации."
          }
          onClose={() => setIsSubmitSuccess(false)}
        />
      )}
      {isSubmitFailed && (
        <FailureModal
          title={"Ошибка отправки заявки"}
          buttonText={"Перейти на Hobbyperform"}
          description={
            errorPayload?.error?.details || "Попробуйте повторить позже"
          }
          onClose={() => setIsSubmitFailed(false)}
          link={process.env.REACT_APP_HOBBYPERFORM_URL}
        />
      )}
    </div>
  );
}

export default App;
