import { configureStore } from '@reduxjs/toolkit';
import amoReducer from './slices/amoSlice';
import infoReducer from './slices/infoSlice';
import utmReducer from './slices/utmSlice';

export const store = configureStore({
  reducer: {
    amo: amoReducer,
    info: infoReducer,
    utm: utmReducer,
  },
});
