import { useTranslation } from "react-i18next";

import { Button } from "@/ui/Button";
import { useAppContext } from "../../context";

import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { useEffect } from "react";
import { fetchInfo } from "@/modules/slices/infoSlice";

export const SellersSaleInfo = () => {
  const { t } = useTranslation("sellersSaleInfo");
  // const { toggleModal } = useAppContext();
  const dispatch = useDispatch();

  const info = useSelector((store) => store.info);

  const linkToHobbyperform = () => {
    window.open("https://hobbyperform.ru/", "_blank");
  };

  useEffect(() => {
    dispatch(fetchInfo());
  }, []);

  return (
    <section className="sellers-sale-info">
      <div className="sellers-sale-info__content container">
        <h2 className={"sellers-sale-info__content-title"}>
          На нашей площадке:
        </h2>
        <div className="info-count">
          {/*<article className="info-count__item">*/}
          {/*    <span className='info-count__item-title'>уже</span>*/}
          {/*    <span className="info-count__item-count">243</span>*/}
          {/*    <h3 className="info-count__item-description">*/}
          {/*        {t("itemDescription1")}*/}
          {/*    </h3>*/}
          {/*</article>*/}
          <article className="info-count__item">
            <span className="info-count__item-title">уже</span>
            <span className="info-count__item-count">
              {info?.data?.data?.attributes?.usersAmount || 0}
            </span>
            <h3 className="info-count__item-description">
              {t("itemDescription2")}
            </h3>
          </article>
        </div>
        <h2 className="sellers-sale-info__content__title">
          {t("title")}&nbsp;
          <span className="red-manuscript__wrapper">{t("manuscript")}</span>!
        </h2>
        <div className="register-your-shop">
          <h3 className="register-your-shop__title">
            Займите свою нишу на <strong>новом</strong> маркетплейсе товаров для
            хобби
          </h3>
          <Button onClick={linkToHobbyperform}>Перейти на маркетплейс</Button>
        </div>
      </div>
    </section>
  );
};
