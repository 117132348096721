import React from "react";
import arrowDown from "@/assets/icons/arrow-down.svg";
import { CSSTransition } from "react-transition-group";

import "./styles.scss";

export const Spoiler = ({ title, content, isOpen, onToggle }) => {
  const handleToggle = () => {
    onToggle();
  };

  return (
    <div className="spoiler">
      <div className="spoiler-head" onClick={handleToggle}>
        <div className="spoiler-head__title">{title}</div>
        <div className={`spoiler-head__icon ${isOpen ? "open-icon" : ""}`}>
          <img src={arrowDown} alt="" />
        </div>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="spoiler__content"
        unmountOnExit
      >
        <div
          className="spoiler__content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </CSSTransition>
    </div>
  );
};
