import React from "react";

import "./styles.scss";

export const StepItem = ({ number, title, description }) => {
  return (
    <div className="how-to-start-selling__item">
      <span className="how-to-start-selling__item-number">{number}</span>
      <div className="item-info">
        <h4 className="item-info__title">{title}</h4>
        <p className="item-info__description">{description}</p>
      </div>
    </div>
  );
};
