import { useState } from "react";
import ReactDOM from "react-dom";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../context";
import { submitAmoRequest } from "@/modules/slices/amoSlice";
import "react-phone-input-2/lib/style.css";

import "./styles.scss";

export const DataModal = ({
  isOpen,
  onClose,
  showSuccessModal,
  showFaliureModal,
  title = "Добро пожаловать в Hobbyperform",
}) => {
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { toggleModal } = useAppContext();
  const dispatch = useDispatch();

  if (!isOpen) return null;

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  const handleNameChange = (e) => {
    const newName = e.target.value;
    setUsername(newName);

    if (newName.length < 2 || newName.length > 50) {
      setNameError('Имя должно содержать от 2 до 50 символов');
    } else {
      setNameError('');
    }
  };

  const handlePhoneChange = (value) => {
    setPhone(value);

    if (value.replace(/[^0-9]/g, '').length < 11) {
      setPhoneError('Номер телефона должен содержать не менее 11 цифр');
    } else {
      setPhoneError('');
    }
  };

  const handleAgreementChange = () => {
    setIsAgreed(!isAgreed);
  };


  const source = localStorage.getItem('source')

  const utm_name = source ? `${source}_landing` : 'Landing'

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitAmoRequest({ username, phone: `+${phone}`, utm_name })).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        showSuccessModal(true);
        onClose();
        setPhone("");
        setUsername("");
        setIsAgreed(false);
      } else {
        showFaliureModal(true);
        onClose();
        setPhone("");
        setUsername("");
        setIsAgreed(false);
      }
    });
  };

  const isFormValid = username && phone && isAgreed && !nameError && !phoneError;

  const handleOpenJivoApi = () => {
    if (window.jivo_api) {
      window.jivo_api.open();
    }
  };

  return ReactDOM.createPortal(
    <div className={"data-modal"} onClick={handleOverlayClick}>
      <div className={"data-modal__content"}>
        <div className="data-modal__close-btn" onClick={onClose} />
        <h2 className={"data-modal__content-title"}>{title}</h2>
        <form onSubmit={handleSubmit}>
          <div className={"fields-wrapper"}>
            <input
              type="text"
              id="name"
              placeholder="Имя"
              value={username}
              onChange={handleNameChange}
              required
              className={nameError ? 'error' : ''}
            />
            {nameError && <div className="error-text">{nameError}</div>}
            <PhoneInput
              id="phone"
              country="ru"
              value={phone}
              enableSearch
              onChange={handlePhoneChange}
            />
          </div>
          <button
            className={`submit__btn ${!isFormValid ? "disabled" : ""}`}
            type="submit"
            disabled={!isFormValid}
          >
            Отправить
          </button>
          <p className="data-modal__content-description">
            Спасибо за регистрацию! Если у вас возникли вопросы - обращайтесь в
            <span className="technical-support" onClick={handleOpenJivoApi}>
              &nbsp;тех. поддержку
            </span>
          </p>
          <div className="accept-rules">
            <input
              type="checkbox"
              checked={isAgreed}
              onChange={handleAgreementChange}
              required
            />
            <span onClick={handleAgreementChange}>
              Согласен с
              <a href="https://hobbyperform.ru/documentation/oferta-ekosistemy-prodavcy.pdf" target="_blank">условиями</a>
              использования сервисов Hobbyperform и
              <a href="https://hobbyperform.ru/documentation/oferta-ekosistemy-prodavcy.pdf" target="_blank">обработки персональных данных</a>
            </span>
          </div>
        </form>
      </div>
    </div>,
    document.querySelector("body"),
  );
};
