import React from "react";

import "./styles.scss";

export const BenefitsItem = ({ icon, title, description }) => {
  return (
    <div className="benefits-item">
      <img src={icon} className="benefits-item__icon" alt="Icon" />
      <p className="benefits-item__title">{title}</p>
      <p className="benefits-item__description">{description}</p>
    </div>
  );
};
