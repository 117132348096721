import React from "react";
import "./styles.scss";

export const BurgerMenu = ({ isOpen, toggleBurgerMenu, children }) => {
  return isOpen ? (
    <div className={`burger-menu ${isOpen ? "open" : ""}`}>
      <div
        className={`burger-icon ${isOpen ? "open" : ""}`}
        onClick={() => toggleBurgerMenu(false)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="menu-items">{children}</div>
    </div>
  ) : null;
};
