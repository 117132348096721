import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { AppProvider } from "./context";
import { Provider } from "react-redux";
import { store } from "modules/store";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
     <BrowserRouter> 
    <AppProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AppProvider>
     </BrowserRouter> 
);
