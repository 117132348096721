import React from "react";

import workWithUs1 from "@/assets/images/work-with-us-1.jpg";
import workWithUs2 from "@/assets/images/work-with-us-2.jpg";
import workWithUs3 from "@/assets/images/work-with-us-3.jpg";

import { Button } from "@/ui/Button";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../context";

import "./styles.scss";

const images = [workWithUs1, workWithUs2, workWithUs3];

export const WorkWithUs = () => {
  // const { toggleModal } = useAppContext();
  const { t, i18n } = useTranslation("workWithUs");

  const phoneNumber = "+79956638924";
  const message = "Добрый день! Меня интересует размещение на Hobbyperform :)";

  const openWhatsApp = () => {
    window.open(
      `whatsapp://send?phone=${phoneNumber}&text=${message}`,
      "_blank",
    );
  };

  return (
    <section className="work-with-us" id="workWithUs">
      <div className="work-with-us__content container">
        <div className="work-with-us__content-left">
          <h2 className="work-with-us__content-left-title">{t("title")}</h2>
          <div className="work-with-us__list">
            <div className="work-with-us__list-item">
              <h4 className="work-with-us__list-item__title">
                Платформа и маркетплейс
              </h4>
              <p className={"work-with-us__list-item__description"}>
                HobbyPerform - это площадка, позволяющая производителям и
                креативным мастерам показать свой продукт миру и
                масштабироваться за счет оптимизации продаж.
              </p>
            </div>
            <div className="work-with-us__list-item">
              <h4 className="work-with-us__list-item__title">Сообщество</h4>
              <p className={"work-with-us__list-item__description"}>
                HobbyPerform - это место, где встречаются те, для кого хобби,
                это еще и бизнес - мастера и ценители уникального и креативного.
              </p>
            </div>
            <div className="work-with-us__list-item">
              <h4 className="work-with-us__list-item__title">
                Ключ к масштабированию
              </h4>
              <p className={"work-with-us__list-item__description"}>
                HobbyPerform - это инструмент, позволяющий производителям
                товаров для хобби заявить о себе всему миру. С нами Вы увеличите
                объёмы и расширите географию продаж.
              </p>
              <p
                className={
                  "work-with-us__list-item__description sub-description"
                }
              >
                Мы планируем выход на рынок Казахстана, Турции и Европы.
              </p>
            </div>
          </div>
          <Button onClick={openWhatsApp}>Написать в WhatsApp</Button>
        </div>
        <div className="work-with-us__content-right">
          {images.map((src, index) => (
            <img key={src} src={src} alt={`work-with-us-${index + 1}`} />
          ))}
        </div>
      </div>
    </section>
  );
};
