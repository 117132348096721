import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const STRAPI_ROUTES = {
    GET_USER_INFO: "/user-option",
};

export const fetchInfo = createAsyncThunk(
    "get/usersInfo",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}${STRAPI_ROUTES.GET_USER_INFO}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    },
);

const infoSlice = createSlice({
    name: "info",
    initialState: {
        loading: false,
        error: null,
        data: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default infoSlice.reducer;
