import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Logo } from "@/assets/icons/hobby_perform-2.svg";
import { ReactComponent as VKIcon } from "@/assets/icons/vk.svg";
import { ReactComponent as InstagramIcon } from "@/assets/icons/instagram.svg";
import { ReactComponent as TelegramIcon } from "@/assets/icons/telegram.svg";

import { FooterMenuItem } from "./FooterMenuItem";
import { useAppContext } from "../../context";

import "./styles.scss";

export const AppFooter = () => {
  const { t } = useTranslation("footer");
  const { toggleModal } = useAppContext();

  const companyLinks = [
    {
      label: "company.link1",
      href: "https://hobbyperform.ru/about",
      targetBlank: true,
    },
    {
      label: "company.link2",
      href: "https://hobbyperform.ru/partner-program",
      targetBlank: true,
    },
    {
      label: "company.link4",
      href: "https://hobbyperform.ru/documentation",
      targetBlank: true,
    },
    {
      label: "company.link7",
      href: "https://hobbyperform.ru/login-seller",
      targetBlank: true,
    },
    {
      label: "company.link8",
      href: "https://hobbyperform.ru/login-seller",
      targetBlank: true,
    },
    {
      label: "company.link9",
      href: "https://hobbyperform.ru/login-seller",
      targetBlank: true,
    },
    {
      label: "company.link10",
      href: "https://hobbyperform.ru/tariffs",
      targetBlank: true,
    },
    {
      label: "company.link11",
      href: "https://hobbyperform.ru/packages",
      targetBlank: true,
    },
  ];

  // const partnersLinks = [
  //   {
  //     label: "partners.link1",
  //     href: "https://hobbyperform.ru/shipping-agreement",
  //   },
  //   {
  //     label: "partners.link2",
  //     href: "https://hobbyperform.ru/privacy-policy",
  //   },
  // ];

  const customersLinks = [
    {
      label: "customers.link1",
      href: "https://hobbyperform.ru/how-to-order",
      targetBlank: true,
    },
    {
      label: "customers.link2",
      href: "https://hobbyperform.ru/payment-methods",
      targetBlank: true,
    },
    {
      label: "customers.link3",
      href: "https://hobbyperform.ru/shipping-agreement",
      targetBlank: true,
    },
    {
      label: "customers.link4",
      href: "https://hobbyperform.ru/documentation/oferta-ekosistemy-prodavcy.pdf",
      targetBlank: true,
    },
    {
      label: "customers.link5",
      href: "https://hobbyperform.ru/documentation/oferta-ekosistemy-pokupateli.pdf",
      targetBlank: true,
    },
    { label: "customers.link6", href: "https://hobbyperform.ru/faq" },
  ];

  const contactLinks = [
    { label: "contacts.link1", href: "tel:88005007597" },
    { label: "contacts.link2", href: "mailto:help@hobbyperform.com" },
    { label: "contacts.link3", href: "mailto:sale@hobbyperform.com" },
    {
      label: "contacts.link4",
      href: "whatsapp://send?phone=+79956638924",
      targetBlank: true,
    },
    {
      label: "contacts.link5",
      href: "https://t.me/hobbyperformsupport",
      targetBlank: true,
    },
  ];

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className={"footer__content-head"}>
          <Logo className="footer-logo" />
          <div className={"subscribe-block"}>
            <input type="text" placeholder={"Введите email"} />
            <button>Подписаться</button>
          </div>
        </div>
        {/*<img  src={logoSVG} alt="Hobbyperform" />*/}
        <div className="footer__content-wrapper">
          <FooterMenuItem title={t("company.title")} links={companyLinks}>
            <div className={"company-action__links"}>
              <span className={"action-link"} onClick={toggleModal}>
                Зарабатывайте в Hobbyperform
              </span>
            </div>
          </FooterMenuItem>
          {/*<FooterMenuItem title={t("partners.title")} links={partnersLinks} />*/}
          <FooterMenuItem title={t("customers.title")} links={customersLinks} />
          <div className={"contacts-and-social-block"}>
            <FooterMenuItem title={t("contacts.title")} links={contactLinks} />
            <FooterMenuItem
              className={"footer-social-networks"}
              title={"Социальные сети"}
            >
              <div className={"footer-social-networks___wrapper"}>
                <a
                  href="https://vk.com/public222273698"
                  target="_blank"
                  className={"social-networks__link"}
                >
                  <VKIcon />
                </a>
                <a
                  href="https://www.instagram.com/hobbyperform/"
                  className={"social-networks__link"}
                  target={"_blank"}
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://t.me/hobbyperform"
                  className={"social-networks__link"}
                  target={"_blank"}
                >
                  <TelegramIcon />
                </a>
              </div>
              <div className={"instagram-annotation"}>
                *Instagram, продукт компании Meta, которая признана
                экстремисткой организацией в России
              </div>
            </FooterMenuItem>
          </div>
        </div>
        <div className="footer__content-copyright-block">
          <div className="company-name">© 2023 «Hobbyperform»</div>
          <div className="question-block">
            <span>{t("questionBlock.title")}</span>
            <span>
              {t("questionBlock.description1")}&nbsp;
              <a href="tel:88005007597">{t("questionBlock.phone")}</a>
            </span>
            <span>{t("questionBlock.description2")}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
