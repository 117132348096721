import React from "react";

import { Button } from "@/ui/Button";
import partnerProgramIMG from "@/assets/images/partner-program.jpg";

import "./styles.scss";

export const PartnerProgram = () => {
  const routeToPartnerProgram = () => {
    window.open("https://hobbyperform.ru/partner-program", "_blank");
  };

  return (
    <section className="partner-program" id="partnerProgram">
      <div className="partner-program__content container">
        <div className="partner-program__content-left">
          <h2 className="content__title">Партнёрская программа</h2>
          <div className="content__description">
            <p>
              Мы даем возможность делится своей реферальной ссылкой для
              регистрации партнеров на нашей платформе!
            </p>
            <p>
              При вступлении в партнерскую программу вы получаете начисления от
              покупок каждого, кто зарегистрировался по вашей ссылке!
            </p>
          </div>
          <div className="content__subdescription">
            Мы работаем с юридическими и физическими лицами!
          </div>
          <Button onClick={routeToPartnerProgram}>Подробнее</Button>
        </div>
        <img
          className="partner-program__content-right"
          src={partnerProgramIMG}
          alt="Партнерская программа"
        />
      </div>
    </section>
  );
};
