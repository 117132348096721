import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/ui/Button";

import svetIMG from "@/assets/images/svet.png";
import decorIMG from "@/assets/images/decor.png";
import plediIMG from "@/assets/images/pledi.webp";
import posydaIMG from "@/assets/images/posyda.png";
import mebelIMG from "@/assets/images/mebel.png";
import { ReactComponent as ArrowRight } from "@/assets/icons/arrow-right.svg";

import { useAppContext } from "../../context";

import "./styles.scss";

export const JoinSellers = () => {
  // const { toggleModal } = useAppContext();

  const phoneNumber = "+79956638924";
  const message = "Добрый день! Меня интересует размещение на Hobbyperform :)";
  const openWhatsApp = () => {
    window.open(
      `whatsapp://send?phone=${phoneNumber}&text=${message}`,
      "_blank",
    );
  };

  const { t } = useTranslation("joinSellers");
  return (
    <section className="join-sellers">
      <div className="join-sellers__content container">
        <h2 className="join-sellers__content-title">{t("title")}</h2>
        <div className="join-sellers__content-wrapper">
          <p className="join-sellers__content-wrapper-description">
            {t("description")}
          </p>
          <Button onClick={openWhatsApp}>Написать в WhatsApp</Button>
        </div>
        <div className={"preview-catalog"}>
          <a
            className="link-to-all-categories"
            href="https://hobbyperform.ru/catalog"
            target="_blank"
          >
            Все категории
          </a>
          <div className={"preview-catalog__wrapper"}>
            <div className={"catalog-images-left"}>
              <a
                className={"catalog-image-1"}
                href={"https://hobbyperform.ru/catalog/7"}
                target={"_blank"}
              >
                <img src={svetIMG} alt="Свет" />
                <h4 className={"block-title"}>Свет</h4>
                <button className={"block-button"}>
                  <ArrowRight />
                </button>
              </a>
              <a
                className={"catalog-image-2"}
                href={"https://hobbyperform.ru/catalog/32"}
                target={"_blank"}
              >
                <img src={decorIMG} alt="Декор" />
                <h4 className={"block-title"}>Декор</h4>
                <button className={"block-button"}>
                  <ArrowRight />
                </button>
              </a>
            </div>
            <div className={"catalog-images-middle"}>
              <a
                className={"catalog-image-3"}
                href={"https://hobbyperform.ru/catalog/24"}
                target={"_blank"}
              >
                <img src={plediIMG} alt="Пледы" />
                <h4 className={"block-title"}>Пледы</h4>
                <button className={"block-button"}>
                  <ArrowRight />
                </button>
              </a>
            </div>
            <div className={"catalog-images-right"}>
              <a
                className={"catalog-image-4"}
                href={"https://hobbyperform.ru/catalog/27"}
                target={"_blank"}
              >
                <img src={posydaIMG} alt="Посуда" />
                <h4 className={"block-title"}>Посуда</h4>
                <button className={"block-button"}>
                  <ArrowRight />
                </button>
              </a>
              <a
                className={"catalog-image-5"}
                href={"https://hobbyperform.ru/catalog/28"}
                target={"_blank"}
              >
                <img src={mebelIMG} alt="Мебель" />
                <h4 className={"block-title"}>Мебель</h4>
                <button className={"block-button"}>
                  <ArrowRight />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
