import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context";
import { Button } from "@/ui/Button";
import { ContactUsWhatsApp } from "@/ui/ContactUsWhatsApp";
import { Slider } from "@/components/StartWithUs/Slider";

import "./styles.scss";

export const StartWithUs = () => {
  const { toggleModal } = useAppContext();
  const { t, i18n } = useTranslation("startWithUs");

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const toggleVideoModal = () => {
    setIsVideoModalOpen(!isVideoModalOpen);
  };

  return (
    <section className="start-with-us">
      <div className="start-with-us__content container">
        <div className="start-with-us__content-left">
          {/*<button className="watch-btn" onClick={toggleVideoModal}>*/}
          {/*  {t("watchBtn")}*/}
          {/*</button>*/}
          {/*<Modal isOpen={isVideoModalOpen} onClose={toggleVideoModal}>*/}
          {/*  <YouTubeVideo videoId="i3J6i3xM32Y" />*/}
          {/*</Modal>*/}
          <h1 className="start-with-us__content-left-title">{t("title")}</h1>
          <div className="become-sellers">
            <div className="become-sellers__wrapper">
              <Button onClick={toggleModal}>{t("buttonText")}</Button>
            </div>
            {/*<p className="become-sellers-description">{t("description")}</p>*/}
            <ContactUsWhatsApp theme={"primary"} />
          </div>
        </div>
        <div className="start-with-us__content-right">
          <Slider />
        </div>
      </div>
    </section>
  );
};
