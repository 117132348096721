import React, { createContext, useContext, useEffect, useState } from "react";
import i18n from "../i18n";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const phoneNumber = "+79956638924";
  //
  // const openWhatsApp = () => {
  //   window.open(`whatsapp://send?phone=${phoneNumber}`, "_blank");
  // };

  useEffect(() => {
    const domain = window.location.hostname;
    let language = "ru";

    if (domain.endsWith(".kz")) {
      language = "kz";
    }

    i18n.changeLanguage(language);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const contextValues = {
    isModalOpen,
    toggleModal,
  };

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
