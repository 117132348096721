import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const AMO_ROUTES = {
  AMO_SUBMITTION: "/amo/submission-request",
};


export const submitAmoRequest = createAsyncThunk(
  "amo/submitRequest",
  async ({ username, phone, utm_name }, { rejectWithValue }) => {

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}${AMO_ROUTES.AMO_SUBMITTION}`,
        {
          username,
          phone,
          utm_name
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const amoSlice = createSlice({
  name: "amo",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitAmoRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitAmoRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(submitAmoRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default amoSlice.reducer;
