import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/ui/Button";
import quickRegistrationIMG from "@/assets/images/quick-registration.png";
import { useAppContext } from "../../context";
import { ContactUsWhatsApp } from "@/ui/ContactUsWhatsApp";

import "./styles.scss";

export const QuickRegistration = () => {
  const { toggleModal } = useAppContext();

  const { t } = useTranslation("quickRegistration");
  return (
    <section className="quick-registration">
      <div className="quick-registration__content container">
        <div className="quick-registration__content-left">
          <img src={quickRegistrationIMG} alt="quick-registration" />
        </div>
        <div className="quick-registration__content-right">
          <h2 className="quick-registration__content-right-title">
            {t("title")}
          </h2>
          <p className="quick-registration__content-right-description">
            {t("description")}
          </p>
          <Button onClick={toggleModal}>{t("buttonText")}</Button>
          <ContactUsWhatsApp theme={"second"} />
        </div>
      </div>
    </section>
  );
};
