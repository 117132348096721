import React from "react";
import howToStartSellingIMG from "@/assets/images/how-to-start-selling.png";
import { Button } from "@/ui/Button";
import { StepItem } from "./StepItem";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../context";
import { ContactUsWhatsApp } from "@/ui/ContactUsWhatsApp";

import "./styles.scss";

const STEPS_DATA = [
  {
    number: 1,
    title: "step1.title",
    description: "step1.description",
  },
  {
    number: 2,
    title: "step2.title",
    description: "step2.description",
  },
  {
    number: 3,
    title: "step3.title",
    description: "step3.description",
  },
];

export const HowToStartSelling = () => {
  const { toggleModal } = useAppContext();
  const { t } = useTranslation("howToStartSelling");
  return (
    <section className="how-to-start-selling" id="howToStartSelling">
      <div className="how-to-start-selling__content container">
        <h2 className="how-to-start-selling__content-title">{t("title")}</h2>
        <p className="how-to-start-selling__content-description">
          {t("description")}
        </p>
        <div className="how-to-start-selling__content-wrapper">
          <div className="how-to-start-selling__content-wrapper-left">
            {STEPS_DATA.map((step) => (
              <StepItem
                key={step.title}
                number={step.number}
                title={t(step.title)}
                description={t(step.description)}
              />
            ))}
            <Button onClick={toggleModal}>{t("buttonText")}</Button>
            <ContactUsWhatsApp theme={"second"} />
          </div>
          <div className="how-to-start-selling__content-wrapper-right">
            <img src={howToStartSellingIMG} alt="how-to-start-selling" />
          </div>
        </div>
      </div>
    </section>
  );
};
