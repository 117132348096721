import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectCards, EffectFade } from "swiper";

import banner1IMG from "@/assets/images/banner-1.png";
import banner2IMG from "@/assets/images/banner-2.png";
import banner3IMG from "@/assets/images/banner-3.png";

import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css";

import "./styles.scss";

SwiperCore.use([Autoplay, EffectFade, EffectCards]);

const SLIDER_DATA = [
  { image: banner1IMG },
  { image: banner2IMG },
  { image: banner3IMG },
];

export const Slider = () => {
  return (
    <Swiper
      spaceBetween={30}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[EffectFade, Autoplay]}
      effect="fade"
    >
      {SLIDER_DATA.map((slide) => {
        return (
          <SwiperSlide key={slide.image}>
            <img className="slider-image" src={slide.image} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
