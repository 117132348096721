import React from "react";
import ReactDOM from "react-dom";

import "./styles.scss";

const RESERVED_SYNTACTIC_CLAUSES = [
  'Введен не правильный номер',
]

export const FailureModal = ({ title, description, buttonText, onClose, link }) => {
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={"failure-modal"} onClick={handleOverlayClick}>
      <div className={"failure-modal__content"}>
        <div className="failure-modal__close-btn" onClick={onClose} />
        <h2 className={"failure-modal__content-title"}>{title}</h2>
        <p className={"failure-modal__content-description"}>{description}</p>
        {
          RESERVED_SYNTACTIC_CLAUSES.filter(words => words === description).length > 0 && <button onClick={onClose}>Закрыть</button> ||
          (
            link === undefined &&
            <button onClick={onClose}>{buttonText}</button> ||
            <a href={link} onClick={onClose} target="_blank" rel="noreferrer">{buttonText}</a>
          )
        }
      </div>
    </div>,
    document.querySelector("body"),
  );
};
