import React from "react";
import { ReactComponent as WhatsAppIcon } from "@/assets/icons/whatsapp.svg";
import "./styles.scss";

export const ContactUsWhatsApp = ({ theme }) => {
  const isPrimaryTheme = theme === "primary";
  const phoneNumber = "+79956638924"; // Замените на нужный номер телефона или ID пользователя
  const message = "Добрый день! Меня интересует размещение на Hobbyperform :)";
  const openWhatsApp = () => {
    window.open(
      `whatsapp://send?phone=${phoneNumber}&text=${message}`,
      "_blank",
    );
  };
  return (
    <div
      className={`contact-us-whatsapp ${
        isPrimaryTheme ? "primary-theme" : "second-theme"
      }`}
    >
      <span
        className={`contact-us-whatsapp__title ${
          isPrimaryTheme ? "primary-text" : "second-text"
        }`}
      >
        Или напишите нам в WhatsApp <WhatsAppIcon />
      </span>
      <button
        className={`contact-us-whatsapp__btn ${
          isPrimaryTheme ? "primary-btn" : "second-btn"
        }`}
        onClick={openWhatsApp}
      >
        Написать в WhatsApp
      </button>
    </div>
  );
};
