import { useState } from "react";
import { useTranslation } from "react-i18next";

import TempImage from "@/assets/images/temp-image.svg";
import { Spoiler } from "@/ui/Spoiler";

import { useAppContext } from "../../context";
import "./styles.scss";
import { Button } from "@/ui/Button";

const FAQ_DATA = [
  {
    title: "faqItem1.title",
    content: "faqItem1.content",
  },
  {
    title: "faqItem2.title",
    content: "faqItem2.content",
  },
  {
    title: "faqItem3.title",
    content: "faqItem3.content",
  },
  {
    title: "faqItem4.title",
    content: "faqItem4.content",
  },
  {
    title: "faqItem5.title",
    content: "faqItem5.content",
  },
  {
    title: "faqItem7.title",
    content: "faqItem7.content",
  },
];

export const WeAreReadyToHelp = () => {
  const [activeSpoiler, setActiveSpoiler] = useState(0);
  const { t } = useTranslation("weAreReadyToHelp");

  const phoneNumber = "+79956638924";
  const message = "Добрый день! Меня интересует размещение на Hobbyperform :)";

  const openWhatsApp = () => {
    window.open(
      `whatsapp://send?phone=${phoneNumber}&text=${message}`,
      "_blank",
    );
  };
  const handleToggleSpoiler = (spoilerIndex) => {
    setActiveSpoiler((prevIndex) =>
      prevIndex === spoilerIndex ? null : spoilerIndex,
    );
  };

  return (
    <section className="we-are-ready-to-help" id="weAreReadyToHelp">
      <div className="we-are-ready-to-help__content container">
        <div className={"we-are-ready-to-help__content-head"}>
          <h2 className="we-are-ready-to-help__content-head-title">
            {t("title")}
          </h2>
          <a
            href="https://hobbyperform.ru/faq"
            className={"link-to-faq"}
            target={"_blank"}
          >
            Открыть FAQ
          </a>
        </div>
        <p className="we-are-ready-to-help__content-head-description">
          {t("description")}
        </p>
        <div className="we-are-ready-to-help__content-wrapper">
          <div className="we-are-ready-to-help__content-wrapper-left">
            <img src={TempImage} alt="" />
            {/*<TempImage />*/}
            <div className="help-block">
              <h4 className="help-block__title">{t("helpBlockTitle")}</h4>
              <p className="help-block__description">
                {t("helpBlockDescription")}
              </p>
              <button className="help-block__btn" onClick={openWhatsApp}>
                Задать вопрос в WhatsApp
              </button>
            </div>
          </div>
          <div className="we-are-ready-to-help__content-wrapper-right">
            <h5>FAQ</h5>
            <div className="spoilers-wrapper">
              {FAQ_DATA.map((item, index) => (
                <Spoiler
                  key={item.title}
                  title={t(item.title)}
                  content={t(item.content)}
                  isOpen={activeSpoiler === index}
                  onToggle={() => handleToggleSpoiler(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
