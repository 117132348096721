import React, { useState } from "react";

import { BurgerMenu } from "@/ui/BurgerMenu";
import { ReactComponent as MenuIcon } from "@/assets/icons/menu.svg";
import { ReactComponent as Logo } from "@/assets/icons/hobby_perform-2.svg";
import { ReactComponent as VKIcon } from "@/assets/icons/vk.svg";
import { ReactComponent as InstagramIcon } from "@/assets/icons/instagram.svg";
import { ReactComponent as TelegramIcon } from "@/assets/icons/telegram.svg";

import { useTranslation } from "react-i18next";
import { LanguageSelector } from "@/components/AppHeader/LanguageSelector";

import { Button } from "@/ui/Button";
import { useAppContext } from "../../context";

import styles from "./styles.scss";

const links = [
  { titleKey: "link1", url: "#workWithUs" },
  { titleKey: "link2", url: "https://hobbyperform.ru/", targetBlank: true },
  { titleKey: "link3", url: "#benefitsForSellers" },
  {
    titleKey: "link4",
    url: "https://hobbyperform.ru/catalog",
    targetBlank: true,
  },
  { titleKey: "link5", url: "#weAreReadyToHelp" },
];

export const AppHeader = () => {
  const { t, i18n } = useTranslation("header");
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const phoneNumber = "+79956638924";
  const message = "Добрый день! Меня интересует размещение на Hobbyperform :)";

  // const { toggleModal } = useAppContext();

  const handleAnchorClick = (event, targetId) => {
    // event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };

  const openWhatsApp = () => {
    window.open(
      `whatsapp://send?phone=${phoneNumber}&text=${message}`,
      "_blank",
    );
  };
  const toggleBurgerMenu = (value) => {
    setIsBurgerMenuOpen(value);
  };
  const handlerBurgerLink = (e, url) => {
    handleAnchorClick(e, url);
    toggleBurgerMenu(false);
  };
  return (
    <header className={"header"}>
      <div className={"header-content"}>
        <div className={"header-top"}>
          <a
            className={"logo"}
            href={"https://hobbyperform.ru/"}
            target={"_blank"}
          >
            <Logo className={"logo-icon"} />
            {/*<span className={styles["logo-title"]}>Партнер</span>*/}
          </a>
          {/*<div className={"header-top__social-networks"}>*/}
          {/*  <a*/}
          {/*    href="https://vk.com/public222273698"*/}
          {/*    target="_blank"*/}
          {/*    className={"social-networks__link"}*/}
          {/*  >*/}
          {/*    <VKIcon />*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href="https://www.instagram.com/hobbyperform/"*/}
          {/*    className={"social-networks__link"}*/}
          {/*    target={"_blank"}*/}
          {/*  >*/}
          {/*    <InstagramIcon />*/}
          {/*  </a>*/}
          {/*  <a*/}
          {/*    href="https://t.me/hobbyperform"*/}
          {/*    className={"social-networks__link"}*/}
          {/*    target={"_blank"}*/}
          {/*  >*/}
          {/*    <TelegramIcon />*/}
          {/*  </a>*/}
          {/*</div>*/}
          <button className={"header-top__btn"} onClick={openWhatsApp}>
            Написать в WhatsApp
          </button>
          {/*<LanguageSelector className={"language-selector__desktop"}/>*/}
          {/*<button className={styles["header-top__learn-btn"]}>*/}
          {/*  Учись с Hobbyeer*/}
          {/*</button>*/}
        </div>
        <nav className={"header-bottom"}>
          <button
            className={"burger__btn"}
            onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
          >
            <MenuIcon />
          </button>
          <div className={"header-bottom__desktop"}>
            {links.map((link) => (
              <a
                key={link.titleKey}
                href={link.url}
                className={"header-bottom__link"}
                target={link.targetBlank ? "_blank" : ""}
                onClick={(e) => handleAnchorClick(e, link.url)}
              >
                {t(link.titleKey)}
              </a>
            ))}
          </div>
          <BurgerMenu
            isOpen={isBurgerMenuOpen}
            toggleBurgerMenu={toggleBurgerMenu}
          >
            <>
              {links.map((link) => (
                <a
                  key={link.titleKey}
                  href={link.url}
                  className={"header-bottom__link"}
                  onClick={(e) => handlerBurgerLink(e, link.url)}
                >
                  {t(link.titleKey)}
                </a>
              ))}
              {/*<LanguageSelector/>*/}
            </>
          </BurgerMenu>
        </nav>
      </div>
    </header>
  );
};
