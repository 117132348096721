import ReactDOM from "react-dom";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";

export const SuccessModal = ({
  title,
  description,
  buttonText,
  onClose,
  link,
}) => {
  const accessToken = useSelector((state) => state.amo.data.tokens.accessToken);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  function RedirectComponent(route) {
    setTimeout(() => window.location.href = `${route}/auth/${accessToken}`, 3000)

    return null;
  }

  useEffect(() => {
    RedirectComponent(process.env.REACT_APP_HOBBYPERFORM_URL)
  })

  return ReactDOM.createPortal(
    <div className={"success-modal"} onClick={handleOverlayClick}>
      <div className={"success-modal__content"}>
        <div className="success-modal__close-btn" onClick={onClose} />
        <h2 className={"success-modal__content-title"}>{title}</h2>
        <p className={"success-modal__content-description"}>{description}</p>
      </div>
    </div>,
    document.querySelector("body"),
  );
};
