import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const UTM_ROUTS = {
  UTM_SUBMITTION: "/utms",
};

export const submitUtmRequest = createAsyncThunk(
  "utms",
  async ( name , { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}${UTM_ROUTS.UTM_SUBMITTION}`,
        {
         data: name
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const utmSlice = createSlice({
  name: "utm",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(submitUtmRequest.pending, (state) => {
  //       state.loading = true;
  //       state.error = null;
  //     })
  //     .addCase(submitUtmRequest.fulfilled, (state, action) => {
  //       state.loading = false;
  //       state.data = action.payload;
  //     })
  //     .addCase(submitUtmRequest.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.payload;
  //     });
  // },
});

export default utmSlice.reducer;