import React from "react";

import orangeCircle from "@/assets/icons/orange-circle.svg";
import { Button } from "@/ui/Button/Button";
import { BenefitsItem } from "./BenefitsItem";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../context";

import "./styles.scss";

const BENEFITS_DATA = [
  {
    icon: orangeCircle,
    title: "benefitItem1.title",
    description: "benefitItem1.description",
  },
  {
    icon: orangeCircle,
    title: "benefitItem2.title",
    description: "benefitItem2.description",
  },
  {
    icon: orangeCircle,
    title: "benefitItem3.title",
    description: "benefitItem3.description",
  },
  {
    icon: orangeCircle,
    title: "benefitItem4.title",
    description: "benefitItem4.description",
  },
];

export const BenefitsForSellers = () => {
  const { toggleModal } = useAppContext();

  const { t } = useTranslation("benefitsForSellers");

  return (
    <section className="benefits-for-sellers" id='benefitsForSellers'>
      <div className="benefits-for-sellers__content container">
        <div className="benefits-for-sellers__content-header">
          <h2 className="benefits-for-sellers__content-header-title">
            {t("title")}
          </h2>
          <Button onClick={toggleModal}>{t("buttonText")}</Button>
        </div>
        <div className="benefits-for-sellers__content-items">
          {BENEFITS_DATA.map((benefit, index) => (
            <div key={index} className={`benefits-item__block-${index + 1}`}>
              <BenefitsItem
                icon={benefit.icon}
                title={t(benefit.title)}
                description={t(benefit.description)}
              />
            </div>
          ))}
        </div>
        <p className="benefits-for-sellers__content-annotation">
          {t("annotation")}
        </p>
      </div>
    </section>
  );
};
