import React from "react";

import {useTranslation} from "react-i18next";

import "./styles.scss";

const FooterLink = ({href, children, targetBlank}) => (
    <a href={href} className="footer-item__link" target={targetBlank ? "_blank" : ""}>
        {children}
    </a>
);

export const FooterMenuItem = ({title, links, children, className}) => {
    const {t} = useTranslation("footer");
    return (
        <div className={`footer__item ${className ? `${className}` : ''}`}>
            <h4 className="footer__item-title">{t(title)}</h4>
            {links &&
                <nav className="footer__item__wrapper">
                    {links.map((link, index) => (
                        <FooterLink key={index} href={link.href} targetBlank={link.targetBlank}>
                            {t(link.label)}
                        </FooterLink>
                    ))}
                </nav>
            }
            {children}
        </div>
    );
};
